/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import React from "react"
import Column from "../components/column"
import Container from "../components/container"
import CTA from "../components/cta"
import Row from "../components/row"
import RichText from "../components/rich-text"
import Text from "../components/text"
import VideoTour from "../components/video-tour"
const BenefitsModule = ({ bladePatternData, ctaOnClick, isReverse= true }) => {
  const [screenWidth, setScreenWidth] = React.useState("")
  const [vedioThumbnail, setVedioThumbnail] = React.useState({})
  React.useEffect(() => {
    if (checkIfWindow) {
      setWindowWidth()
    }
  }, [])
  
  const setWindowWidth = () => {
    setScreenWidth(window.screen.availWidth)
    setVedioThumbnail(
      window.screen.availWidth < 700 && bladePatternData.mobileImage
        ? bladePatternData.mobileImage
        : bladePatternData.desktopImage
    )
  }

  const checkIfWindow = () => {
    if (typeof window !== "undefined") {
      window.addEventListener("orientationchange", setWindowWidth)
      window.addEventListener("resize", setWindowWidth)
      return typeof window !== "undefined"
    }
    return typeof window !== "undefined"
  }

  let isMobile = (checkIfWindow && screenWidth < 700) ;

  return (
    <div
      id={bladePatternData.anchorLink}
      sx={{
        py: theme => [
          theme.spacing.vertical.md,
          theme.spacing.vertical.md,
          theme.spacing.vertical.lg,
        ],
      }}
    >
      <Container>
        <Row
          customStyle={{
            flexDirection: ["column", "column",isReverse ? "row-reverse":"row"],
          }}
        >
          <Column customStyle={{width:"100%"}}>
            {/* TODO: this shuld be updated to a carousel */}
            {bladePatternData.desktopImage && (bladePatternData.anchorLink &&
              bladePatternData.anchorLink.indexOf("youtube.com") === -1)   ? (
                  <img
                    src={bladePatternData.desktopImage.file.url}
                    sx={{
                      ml: [0, 0, 100],
                      mb: theme => [
                        theme.spacing.vertical.sm,
                        theme.spacing.vertical.sm,
                        0,
                      ],
                    }}
                    alt=""
                  />
                ) : null}
            {bladePatternData.anchorLink &&
              bladePatternData.anchorLink.indexOf("youtube.com") !== -1 && (
                <VideoTour
                  video={{
                    title: "",
                    externalUrl: { externalUrl: bladePatternData.anchorLink },
                    thumbnailImage:{...vedioThumbnail}
                  }}
                  customStyle={{
                    width: isMobile ? "100%" :"calc(100% - 100px)" ,
                    marginLeft: isMobile || !isReverse ? "0px":"100px",
                    paddingBottom:isMobile ? "100% !important":"55% !important"
                  }}
                />
              )}
              
              {bladePatternData.description ? (
                <Text 
                  customStyle={{fontSize:17, width: isMobile ? "100%" :"calc(100% - 100px)" ,
                  marginLeft: isMobile || !isReverse ? "0px":"100px",
                  paddingBottom:isMobile ? "100% !important":"55% !important"}}
                  >
                  {bladePatternData.description.description}</Text>
                ) : null}
          </Column>
          <Column customStyle={{ px: [20, 20, 10] }} size={[12, 12, 5]}>
            <div>
              {bladePatternData.contentCards.map((basicCard, idx) => (
                <Row
                  key={idx}
                  customStyle={{
                    mb: theme => theme.spacing.vertical.sm,
                    "p:last-of-type": {
                      mb: 0,
                    },
                  }}
                >
                  <Text type="h3">{basicCard.title}</Text>
                  {basicCard.description ? (
                    <div>
                      <RichText
                        data={basicCard.description}
                        textStyle={
                          !basicCard.title
                            ? {
                                mt: "-10px !important",
                                fontSize:17
                              }
                            : {fontSize:17}
                        }
                      />
                    </div>
                  ) : null}
                  {basicCard.ctaTitle ? (
                    <CTA
                      customStyle={{
                        mt: 20,
                      }}
                      link={
                        (basicCard.ctaDestination &&
                          basicCard.ctaDestination.ctaDestination) ||
                        (basicCard.ctaDestinationExternal &&
                          basicCard.ctaDestinationExternal
                            .ctaDestinationExternal) ||
                        "/"
                      }
                      type={"secondary"}
                      linkType={
                        basicCard.ctaDestinationExternal
                          ? "external"
                          : "internal"
                      }
                      {...{
                        onClick: e =>
                          ctaOnClick(e, idx, {
                            to:
                              (basicCard.ctaDestination &&
                                basicCard.ctaDestination.ctaDestination) ||
                              (basicCard.ctaDestinationExternal &&
                                basicCard.ctaDestinationExternal
                                  .ctaDestinationExternal) ||
                              "/",
                            type: basicCard.ctaDestinationExternal
                              ? "external"
                              : "internal",
                          }),
                      }}
                    >
                      {basicCard.ctaTitle}
                    </CTA>
                  ) : null}
                </Row>
              ))}
            </div>
            <div>
              {bladePatternData.ctaTitle ? (
                <CTA
                  link={bladePatternData.ctaDestination || "/"}
                  type={"secondary"}
                  linkType="internal"
                >
                  {bladePatternData.ctaTitle}
                </CTA>
              ) : null}
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  )
}

BenefitsModule.propTypes = {
  bladePatternData: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    ctaTitle: PropTypes.string,
    ctaDestination: PropTypes.string,
    description: PropTypes.object,
    desktopImage: PropTypes.object,
    mobileImages: PropTypes.object,
    contentCards: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        ctaTitle: PropTypes.string,
        ctaDestination: PropTypes.object,
        description: PropTypes.object,
        desktopImage: PropTypes.object,
        mobileImages: PropTypes.object,
      })
    ),
  }),
}

export default BenefitsModule
